<template>
  <div class="register">
    <b-container class="info">
      <h1>Alias-Anfrage bestätigen</h1>
      <b-spinner
        v-if="this.msg_text == ''"
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <h2>{{ $route.params.q }}</h2>
      <b-row v-if="msg_text != ''">
        <b-col>
          <b-alert show v-bind:variant="msg_type">
            <h4>{{ msg_text }}</h4>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AliasConfirm",
  data() {
    return {
      msg_text: "",
      msg_type: "",
    };
  },
  methods: {},
  mounted() {
    axios
      .get("/api/aliasconfirm/" + this.$route.params.u)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        this.msg_type = "success";
        this.msg_text = response.data;
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        this.msg_type = "warning";
        this.msg_text = error.response.data;
      });
  },
};
</script>
